<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-toolbar-title>{{ categoryTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu transition="slide-y-transition" left bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text tile v-on="on">
            <span class="d-none d-sm-flex mr-1">Сортировка</span>
            <v-icon>$vuetify.icons.sort</v-icon>
          </v-btn>
        </template>
        <v-list class="text-right">
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, index) in sortings"
              :key="index"
              :class="item.val === sort ? 'active v-list-item--active' : ''"
              @click="sortCategory(item.val)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <v-row align="center">
        <no-content v-if="books.length == 0" route-name title="На данный момент у вас нет книг, которые вы купили на нашем сайте."></no-content>
        <book-card
          v-for="(book, index) in books"
          :key="book.id"
          :book="book"
          :index="index"
          :library="false"
          :cabinet="false"
          @remove-library-book-modal="deleteBookModal"
        ></book-card>
      </v-row>
      <v-row align="center">
        <v-col cols="12">
          <v-pagination
            v-if="pages > 1"
            @input="goPage"
            v-model="page"
            :length="pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogDeleteBook" class="text-center" max-width="290">
        <v-card>
          <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

          <v-card-text class="text-center">Что хотите удалить данную книгу из библиотеки.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteBook">Да</v-btn>

            <v-btn color="green darken-1" text @click="dialogDeleteBook = false">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookCard from "../book/BookCard.vue";
export default {
  components: {
    BookCard
  },
  data: () => ({
    sort: "new",
    sortings: [
      { text: "По новизне", val: "new" },
      { text: "По популярности", val: "popular" },
      { text: "По комментариям", val: "comments" }
    ],
    page: 1,
    pages: 0,
    categoryTitle: "Купленные книги",
    dialogDeleteBook: false,
    deleteData: null
  }),
  methods: {
    getBooks() {
      let app = this;
      this.$store
        .dispatch("account/getPurchases", {
          page: app.page,
          sort: app.sort
        })
        .then(function(response) {
          if (response.data.success == 1) {
            app.pages = response.data.books.last_page;
          }
        });
    },
    goPage(nextPage) {
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          params: {},
          query: { page: nextPage, sort: this.sort }
        });
      }
    },
    sortCategory(type) {
      if (type != this.sort) {
        this.sort = type;
        this.$router.push({
          name: this.$route.name,
          params: {},
          query: { page: this.page, sort: this.sort }
        });
      }
    },
    deleteBookModal(data) {
      this.deleteData = data;
      this.dialogDeleteBook = true;
    },
    deleteBook() {
      let app = this;
      app.dialogDeleteBook = false;

      this.$store
        .dispatch("account/dellLibraryBook", {
          page: app.page,
          sort: app.sort,
          deleteData: app.deleteData
        })
        .then(function(response) {
          app.pages = response.data.books.last_page;
          app.deleteData = null;
          // если удалили последнюю книгу на странице перекинем на последнюю страницу
          if (
            response.data.books.last_page < response.data.books.current_page
          ) {
            app.goPage(response.data.books.last_page);
          }
        });
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      books: "account/books"
    })
  },
  mounted() {
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort ? this.$route.query.sort : "new";
    this.getBooks();
  }
};
</script>
